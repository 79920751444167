import React, { useState } from "react";
import { Link } from "gatsby";
import * as styles from "./header.module.scss";

const Header = () => {
	const [isHeaderActive, setIsHeaderActive] = useState(false);

	return (
		<header
			className={`${styles.header} ${isHeaderActive ? "active" : ""}`}
		>
			<img
				className={styles.wood}
				src="/img/header-wood.png"
				alt="Drewno"
			/>
			<div className="container">
				<div className={styles.headerWrapper}>
					<Link to="/">
						<img
							src="/img/logo.svg"
							alt="Zakład Garmażeryjny Brodway Brodła Logo"
						/>
					</Link>

					<div
						onClick={() => setIsHeaderActive(!isHeaderActive)}
						className={styles.headerTrigger}
					>
						<span />
						<span />
						<span />
					</div>

					<nav onClick={() => setIsHeaderActive(!isHeaderActive)}>
						<ul>
							<li>
								<Link to="/">Start</Link>
							</li>
							<li>
								<Link to="#products">Nasze produkty</Link>
							</li>
							<li>
								<Link to="/chrzanolandia">Chrzanolandia</Link>
							</li>
							<li>
								<Link to="#contact">Kontakt</Link>
							</li>
						</ul>
						<ul className={styles.social}>
							<li>
								<a
									href="https://www.facebook.com/ZakladGarmazeryjnyBrodway"
									target="_blank"
								>
									<img
										src="/img/icons/facebook.svg"
										alt="Zakład Garmażeryjny Brodway Brodła Facebook"
									/>
								</a>
							</li>
							<li>
								<a href="mailto: z.g.b@op.pl">
									<img
										src="/img/icons/email.svg"
										alt="Zakład Garmażeryjny Brodway Brodła Email"
									/>
									<span>z.g.b@op.pl</span>
								</a>
							</li>
							<li>
								<a href="tel: 122832860">
									<img
										src="/img/icons/phone.svg"
										alt="Zakład Garmażeryjny Brodway Brodła Telefon"
									/>
									<span>12/28-32-860</span>
								</a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</header>
	);
};

export default Header;
