/* eslint-disable max-len */
import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ( { title, description, image } ) => {
	const SeoTags = [
		{
			name: 'description',
			content: title
		},
		{
			property: 'og:title',
			content: title
		},
		{
			property: 'og:description',
			content: description
		},
		{
			property: 'og:image',
			content: image ? image : ''
		},
		{
			property: 'og:type',
			content: 'website'
		},
		{
			name: 'twitter:title',
			content: title
		},
		{
			name: 'twitter:card',
			content: 'summary_large_image'
		}
	];

	return (
		<Helmet title={title} meta={SeoTags} htmlAttributes={{ lang: 'en' }}>
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
			<link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet" />
		</Helmet>
	);
};

export default Seo;
